/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import Img from 'gatsby-image'

interface Props {
  url: string
  children?: React.ReactNode
}

const Banner = ({ url, children }: Props) => {
  return (
    <Box sx={{ position: 'relative' }} className="banner">
      <Img
        fluid={{ ...url, aspectRatio: 1.77 }}
        // fixed={{
        //   src: url,
        //   srcSet: url,
        //   width: 1490,
        //   height: 850,
        // }}
        sx={{
          mb: 'md1',
        }}
      />
      {children}
    </Box>
  )
}

export default Banner
